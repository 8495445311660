import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),
        allVariants: {
            color: 'black', // Set the default color for all typography variants to black
        },
    },
    palette: {
        primary: {
            main: '#f7971c', // Orange branding color
            light: '#f69f36', // Light branding color
            contrastText: '#000', // Text color on primary buttons
        },
        background: {
            dark: '#343a40', // Dark background color
        },
        grey: {
            main: '#c1c1c1', // Main grey
            light: '#d3d3d3', // Light grey
            dark: '#a9a9a9', // Dark grey
        },
    },
    breakpoints: {
        values: {
            xxs: 0,
            xs: 320, // matches 'mobile'
            sm: 768, // matches 'tablet'
            md: 1024, // matches 'laptop'
            lg: 1440, // matches 'desktop'
        },
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                    padding: '0px 16px 3px 16px',
                    fontSize: '14px', // or '16px'
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#1976d2', // Blue color for links
                    textDecoration: 'none', // No text decoration
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained', // Set the default variant to contained
            },
            styleOverrides: {
                root: {
                    boxShadow: 'none', // Remove the default box shadow
                    textTransform: 'capitalize', // Apply capitalization to all buttons
                    '&:hover': {
                        backgroundColor: '#ff9800', // Specific hover color
                        boxShadow: 'none', // Remove shadow on hover
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'dark' }, // Dark button variant
                    style: ({ theme }) => ({
                        backgroundColor: theme.palette.background.dark, // Dark background color button
                        color: '#fff', // Text color on dark buttons
                        '&:hover': {
                            backgroundColor: '#23272b', // Dark background color button hover
                        },
                    }),
                },
            ],
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true, // Disable ripple effect
                disableFocusRipple: true, // Disable focus ripple effect
            },
            styleOverrides: {
                root: {
                    color: 'black', // Set the color of all IconButtons to black
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 8, // Set the border radius 8px for rounded corners
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#000', // Black background
                },
            },
            defaultProps: {
                placement: 'top', // Default placement of tooltip
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: 10, // Customize the thickness
                    borderRadius: 5, // Rounded corners for the container
                },
                bar: {
                    borderRadius: 5, // Rounded corners for the bar
                    backgroundColor: '#f7971c', // Primary color for the progress bar
                },
            },
        },
    },
});
export default theme;
