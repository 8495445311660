import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from './errors/ErrorBoundary';
import ProtectedRoute from '../routes/ProtectedRoute';

const MenuPagesList = lazy(() =>
    import('./content/contentLogic/MenuPagesList'),
);
const MenuPageDetail = lazy(() =>
    import('./content/contentLogic/MenuPageDetail'),
);
const SettingPage = lazy(() => import('../pages/settings/SettingPage'));
const HomePageList = lazy(() => import('./content/contentLogic/HomePageList'));
const PlanPage = lazy(() => import('../pages/payments/PlanPage'));
const SuccessPage = lazy(() => import('../pages/payments/SuccessPage'));
const AccountModal = lazy(() => import('./reusable/modal/AccountModal'));
const PaymentHistoryPage = lazy(() =>
    import('./profilePage/payment/PaymentHistoryPage'),
);
const PaymentSuccessPage = lazy(() =>
    import('./profilePage/payment/PaymentSuccessPage'),
);

const Content = ({ headerLogo, isMobile }) => {
    return (
        <Suspense>
            <section className="content top-85 section">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <ErrorBoundary>
                                <ProtectedRoute
                                    element={
                                        <HomePageList isMobile={isMobile} />
                                    }
                                />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/:menu"
                        element={
                            <ErrorBoundary>
                                <ProtectedRoute element={<MenuPagesList />} />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/:menu/:slug"
                        element={
                            <ErrorBoundary>
                                <ProtectedRoute element={<MenuPageDetail />} />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/settings"
                        element={
                            <ErrorBoundary>
                                <ProtectedRoute element={<SettingPage />} />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/settings/billing/plans"
                        element={
                            <ErrorBoundary>
                                <PlanPage />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/settings/billing/success"
                        element={
                            <ErrorBoundary>
                                <SuccessPage />
                            </ErrorBoundary>
                        }
                    />
                    <Route path="/payment">
                        <Route
                            path="history"
                            element={
                                <ErrorBoundary>
                                    <PaymentHistoryPage />
                                </ErrorBoundary>
                            }
                        />
                        <Route
                            path="success"
                            element={
                                <ErrorBoundary>
                                    <PaymentSuccessPage />
                                </ErrorBoundary>
                            }
                        />
                    </Route>
                </Routes>
                <Suspense fallback={<div>Loading...</div>}>
                    <AccountModal headerLogo={headerLogo} isMobile={isMobile} />
                </Suspense>
            </section>
        </Suspense>
    );
};

export default Content;
