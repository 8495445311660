import React, { lazy, Suspense, useState } from 'react';

import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { AlertMessageContext } from './context/AlertMessageContext';
import { LoaderProvider } from './context/LoaderContext';
import { AccountModalProvider } from './context/AccountModalContext';
import { UpgradeModalProvider } from './context/UpgradeModalContext';
import { UserSettingsProvider } from './context/UserSettingsContext';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/profile/ProfilePage';
import NotFound from './pages/NotFoundPage';
import { ThemeProvider } from '@mui/material/styles';
import theme from './css/muiStyles/theme';
import './App.css';

const AlertMessages = lazy(() => import('./components/reusable/AlertMessages'));

const App = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    return (
        <HelmetProvider>
            <LoaderProvider>
                <AlertMessageContext.Provider
                    value={{
                        successMessage,
                        errorMessage,
                        setSuccessMessage,
                        setErrorMessage,
                    }}
                >
                    <AccountModalProvider>
                        <UpgradeModalProvider>
                            <ThemeProvider theme={theme}>
                                <div className="App">
                                    <Routes>
                                        <Route
                                            path="/*"
                                            element={
                                                <UserSettingsProvider>
                                                    <HomePage />
                                                </UserSettingsProvider>
                                            }
                                        />
                                        <Route
                                            path="/user/:user_slug"
                                            element={<ProfilePage />}
                                        />
                                        <Route
                                            path="not-found"
                                            element={<NotFound />}
                                        />
                                    </Routes>

                                    <Suspense fallback={<div>Loading...</div>}>
                                        <AlertMessages />
                                    </Suspense>
                                </div>
                            </ThemeProvider>
                        </UpgradeModalProvider>
                    </AccountModalProvider>
                </AlertMessageContext.Provider>
            </LoaderProvider>
        </HelmetProvider>
    );
};

export default App;
