import { createContext, useState } from 'react';

export const LoaderContext = createContext({
    showLoader: false,
    setShowLoader: () => {},
    loaderMessage: 'Loading',
    setLoaderMessage: () => {},
});

export const LoaderProvider = ({ children }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState(false);

    return (
        <LoaderContext.Provider
            value={{
                showLoader,
                setShowLoader,
                loaderMessage,
                setLoaderMessage,
            }}
        >
            {children}
        </LoaderContext.Provider>
    );
};
